/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
'use client'

import { type Cart } from '@/lib/generated/graphql'
import { ErrorBoundary } from '@sentry/nextjs'
import { type ReactNode, Suspense, use, useTransition } from 'react'
import { useCart } from './cart-context'
import { useEffectOnce } from 'react-use'
import { type UserCart } from './cart-types'

export const CartFiller = ({
  cartPromise,
  newCartPromise,
  children,
}: {
  cartPromise?: Promise<Cart | false>
  newCartPromise?: Promise<UserCart | false>
  children?: ReactNode
}) => {
  return (
    <ErrorBoundary>
      <Suspense>
        <CartFillerSafe
          cartPromise={cartPromise}
          newCartPromise={newCartPromise}
        />
        {children}
      </Suspense>
    </ErrorBoundary>
  )
}

const CartFillerSafe = ({
  cartPromise,
  newCartPromise,
}: {
  cartPromise?: Promise<Cart | false>
  newCartPromise?: Promise<UserCart | false>
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isPending, startTransaction] = useTransition()
  const { setCart } = useCart()
  let cart: Cart | undefined
  if (cartPromise != null) {
    const cartResult = use(cartPromise)
    if (cartResult !== false) {
      cart = cartResult
    }
  }

  let userCart: UserCart | undefined
  if (newCartPromise != null) {
    const newCartResult = use(newCartPromise)
    if (newCartResult !== false) {
      userCart = newCartResult
    }
  }

  useEffectOnce(() => {
    startTransaction(() => {
      setCart(cart, userCart)
    })
  })

  return <></>
}
